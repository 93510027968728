import React from "react";
import styled from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import { isNil, toNumber } from "lodash";
import {
  getCorrectStyleVersionToUse,
  QUERIES_STYLE_VERSION_2,
  QUERIES_STYLE_VERSION_3,
} from "../util-reports";

const BODY_HEIGHT = 70;

export const useStyles = makeStyles((theme) => ({
  headCell: {
    ...theme.palette.tableNew.header,
  },
  headFilterCell: {
    backgroundColor: theme.palette.background.paper,
  },
  headTotalCell: {
    backgroundColor: theme.palette.table.totalsRowBg,
    fontWeight: "bold",
    fontSize: 14,
  },
  iconExternal: {
    color: "yellowgreen",
    fontSize: "0.95rem",
    width: "auto",
    height: "auto",
    padding: 1,
  },
  loadingTable: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#fbfbfb",
    backgroundSize: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    opacity: 0.7,
    transform: "scale(1.01)",
    borderRadius: 10,
  },
  loader: {
    position: "absolute",
    top: "80%",
    right: "50%",
  },
  loaderData: {
    position: "absolute",
    top: "50%",
    right: "50%",
  },
  paperContent: {
    position: "relative",
  },
  openFormImage: {
    width: 20,
    height: 20,
  },
  groupedCellContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: 5,
  },
  totalsRowsAggFunContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const StylesV3 = styled.div`
  padding: 4px 10px;
  display: block;
  max-width: 100%;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow: auto;
  }

  .table {
    overflow-x: auto;
    border-spacing: 0px 10px; /* Space between COLUMNS (0px) and ROWS (10px) */
    border-collapse: separate;
    padding: 8px 3px;
  }

  .tableWrap thead tr:nth-child(1) th {
    position: sticky;
    top: 0;
    z-index: 2;
    font-weight: bold;
    font-size: 14px;
    padding: 8px 4px !important;
    background-color: ${({ muiTheme }) =>
      !isNil(muiTheme) && muiTheme.palette.type === "light"
        ? `#F5F3F5`
        : muiTheme.palette.background.paper};
    color: ${({ muiTheme }) =>
      !isNil(muiTheme) ? muiTheme.palette.content.mainColor : "black"};

    /* Apply border-radius to the first and last header cell */
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .tableWrap thead tr:nth-child(2) th {
    position: sticky;
    top: 36px;
    z-index: 1;
    padding: 0px 4px !important;
    background-color: ${({ muiTheme }) =>
      !isNil(muiTheme) && muiTheme.palette.type === "light"
        ? `#F5F3F5`
        : muiTheme.palette.background.paper};
    color: ${({ muiTheme }) =>
      !isNil(muiTheme) ? muiTheme.palette.content.mainColor : "black"};

    /* Apply border-radius to the first and last header cell */
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .tableWrap thead tr:nth-child(3) th {
    position: sticky;
    top: 61px;
    z-index: 1;
    padding: 4px 4px !important;
    background-color: ${({ muiTheme }) =>
      !isNil(muiTheme) ? muiTheme.palette.table.totalsRowBg : "inherit"};

    /* Apply border-radius to the first and last header cell */
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .tableContainer {
    max-height: ${({ devicePixelRatio, wd }) =>
      !isNil(devicePixelRatio) && devicePixelRatio > 1 && wd?.width > 1460
        ? `calc(${BODY_HEIGHT / devicePixelRatio}vh - 60px)`
        : `calc(${BODY_HEIGHT}vh - 60px)`};
  }

  .pagination {
    padding: 0.5rem;
  }

  .checkbox {
    color: #003366;
    padding: 5px;
  }

  .Mui-checked {
    color: #003366;
  }

  .table-header-row {
  }

  .headCell {
    color: #003366;
    border-bottom: none;
  }

  .table-header-filter-row {
    border-bottom: none;
  }

  .headFilterCell {
    border-bottom: none;
    padding: 0px 4px !important;
  }

  .headTotalCell {
    border-bottom: none;
    font-weight: bold;
    font-size: 14px;
  }

  .tableBodyRow {
  }

  .tableBodyCell {
    background-color: ${({ muiTheme }) =>
      !isNil(muiTheme) && muiTheme.palette.type === "light"
        ? "#E0ECF8"
        : "#2d496a"};
    color: ${({ muiTheme }) =>
      !isNil(muiTheme) && muiTheme.palette.type === "light"
        ? "#000000"
        : muiTheme.palette.text.primary};
    border-bottom: none;
    padding: 0px 4px !important;

    /* Apply border-radius to the first and last cell in each body row */
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const StylesV2 = styled.div`
  padding: 4px 10px;
  display: block;
  max-width: 100%;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow: auto;
  }

  .tableWrap thead tr:nth-child(1) th {
    position: sticky;
    top: 0;
    z-index: 2;
    font-weight: bold;
    font-size: 14px;
    padding: 0px 4px !important;
    background-color: ${({ muiTheme }) =>
      !isNil(muiTheme) && muiTheme.palette.type === "light"
        ? `#99b2d6`
        : muiTheme.palette.content.mainColor};
    color: ${({ muiTheme }) =>
      !isNil(muiTheme) && muiTheme.palette.type === "light"
        ? "#ffffff"
        : muiTheme.palette.text.primary};
  }

  .tableWrap thead tr:nth-child(2) th {
    position: sticky;
    top: 25px;
    z-index: 1;
    padding: 0px 4px !important;
    background-color: ${({ muiTheme }) =>
      !isNil(muiTheme) && muiTheme.palette.type === "light"
        ? "#99b2d6"
        : muiTheme.palette.content.mainColor};
  }

  .tableWrap thead tr:nth-child(3) th {
    position: sticky;
    top: 50px;
    z-index: 1;
  }

  .tableContainer {
    max-height: ${({ devicePixelRatio, wd }) =>
      !isNil(devicePixelRatio) && devicePixelRatio > 1 && wd?.width > 1460
        ? `calc(${BODY_HEIGHT / devicePixelRatio}vh - 60px)`
        : `calc(${BODY_HEIGHT}vh - 60px)`};
    background-color: ${({ muiTheme }) =>
      !isNil(muiTheme) && muiTheme.palette.type === "light"
        ? "#135bb157"
        : "transparent"};
  }

  .table {
    overflow-x: auto;
    border-spacing: 4px; /* Space between rows */
    border-collapse: separate; /* Avoid edge collapse for spacing */
    padding: 8px 3px;
  }

  .pagination {
    padding: 0.5rem;
  }

  .checkbox {
    color: #003366;
    padding: 5px;
  }

  .Mui-checked {
    color: #003366;
  }

  .table-header-row {
    background-color: #93a6bf;
  }

  .headCell {
    background-color: #93a6bf;
    color: #003366;
    border-bottom: none;
  }

  .table-header-filter-row {
    border-bottom: none;
  }

  .headFilterCell {
    border-bottom: none;
    padding: 0px 4px !important;
  }

  .headTotalCell {
    border-bottom: none;
    background-color: ${({ muiTheme }) =>
      !isNil(muiTheme) ? muiTheme.palette.table.totalsRowBg : "inherit"};
    font-weight: bold;
    font-size: 14px;
  }

  .tableBodyRow {
    margin-bottom: 10px; /* Space between rows */
  }

  .tableBodyCell {
    background-color: ${({ muiTheme }) =>
      !isNil(muiTheme) && muiTheme.palette.type === "light"
        ? `#e4ebeb`
        : muiTheme.palette.background.paper};
    color: ${({ muiTheme }) =>
      !isNil(muiTheme) && muiTheme.palette.type === "light"
        ? "#000000"
        : muiTheme.palette.text.primary};
    border-bottom: none;
    padding: 0px 4px !important;
  }
`;

const StylesV1 = styled.div`
  padding: 1rem;
  display: block;
  max-width: 100%;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow: auto;
  }

  .tableWrap thead tr:nth-child(1) th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .tableWrap thead tr:nth-child(2) th {
    position: sticky;
    top: 36px;
    z-index: 1;
  }

  .tableWrap thead tr:nth-child(3) th {
    position: sticky;
    top: 62px;
    z-index: 1;
  }

  .tableContainer {
    max-height: ${({ devicePixelRatio, wd }) =>
      !isNil(devicePixelRatio) &&
      devicePixelRatio > 1 &&
      toNumber(wd?.height) > 0
        ? toNumber(wd?.height) > 700
          ? `${wd?.height / 2}px`
          : `${wd?.height / 3}px`
        : "calc(65vh - 60px)"};
  }

  .table {
    overflow-x: auto;
  }

  .pagination {
    padding: 0.5rem;
  }

  .checkbox {
    color: #b1b1b1;
    padding: 5px;
  }

  .Mui-checked {
    color: #66bb6a;
  }
`;

export const Styles = ({ stylesVersion, children, ...other }) => {
  const stylesVersionToApply = getCorrectStyleVersionToUse({ stylesVersion });
  if (stylesVersionToApply === QUERIES_STYLE_VERSION_2) {
    return <StylesV2 {...other}>{children}</StylesV2>;
  } else if (stylesVersionToApply === QUERIES_STYLE_VERSION_3) {
    return <StylesV3 {...other}>{children}</StylesV3>;
  } else {
    // default the old one
    return <StylesV1 {...other}>{children}</StylesV1>;
  }
};

export const StyledInput = withStyles(() => ({
  root: {
    flex: 1,
  },
  input: {
    padding: "5px 4px",
  },
}))(Input);
