import { useEffect, useState } from "react";
import {
  LOCALE_ENGLISH,
  LOCALE_PROP,
  LOCALE_VALID_WITH_COUNTRY_LIST,
} from "../../util/Constants";
import { useTranslation } from "react-i18next";
import { includes, isEmpty, isNil, isString, toLower } from "lodash";

import { getUserLocale } from "get-user-locale";

export function getDetectedLanguage() {
  const rawDetected =
    getUserLocale() || navigator.language || navigator.userLanguage;
  if (
    !isNil(rawDetected) &&
    !isEmpty(rawDetected) &&
    isString(rawDetected) &&
    includes(rawDetected, "-") &&
    !includes(LOCALE_VALID_WITH_COUNTRY_LIST, toLower(rawDetected))
  ) {
    return rawDetected.split("-")?.[0];
  } else {
    return rawDetected;
  }
}

export const useLocale = () => {
  const [locale, setLocale] = useState(LOCALE_ENGLISH);
  const { i18n } = useTranslation();

  const changeLocale = (locale) => {
    if (!isNil(locale)) {
      window.localStorage.setItem(LOCALE_PROP, locale);
      setLocale(locale);
      i18n.changeLanguage(locale);
    }
  };

  useEffect(() => {
    const cachedLocale = window.localStorage.getItem(LOCALE_PROP);
    cachedLocale && setLocale(cachedLocale);
  }, []);

  return [locale, changeLocale];
};
