/* eslint-disable no-console */
//import "./wdyr";

/* Import statements */
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

/* App is the entry point to the React code.*/
import App from "./app/App";

/* Import i18 configuration */
import "./i18n";

/* Import global styles */
import "./index.css";

// eslint-disable-next-line
import * as cff from "./core/functions/FormFunctions";

import "react-toastify/dist/ReactToastify.css";

//import * as serviceWorker from './serviceWorker';

import { disableReactDevToolsInProduction } from "./disableRDT";
import { TOAST_CONTAINER_LAYOUT } from "./util/Constants";
import SuspenseFallbackLoader from "./components/suspense/fallback/SuspenseFallbackLoader";
import GenericToastContainer from "./components/toast/GenericToastContainer";

if (process.env.NODE_ENV === "production") disableReactDevToolsInProduction();

// if (
//   process.env.NODE_ENV === "production" &&
//   process.env.REACT_APP_ENV !== "QA"
// ) {
//   console.clear();
//   console.log = () => {};
//   console.debug = () => {};
// }

ReactDOM.render(
  <Suspense fallback={<SuspenseFallbackLoader />}>
    <GenericToastContainer containerId={TOAST_CONTAINER_LAYOUT} />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>,
  document.getElementById("reactapp-root")
);

// serviceWorker.register();
