const createQuickViewSlice = (set) => ({
  selectedProduct: null,
  openQuickView: (product) => set({ selectedProduct: product }),
  closeQuickView: () => set({ selectedProduct: null }),
  resetQuickViewSlice: () => {
    set({
      selectedProduct: null,
    });
  },
});

export default createQuickViewSlice;
