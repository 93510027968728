import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import useInfo from "../../../store/hooks/useInfo";
import { isArray, isEmpty, isFunction, isNil } from "lodash";

const useStyles = makeStyles(() => ({
  bodySubtitle: {
    gap: "6px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  actionBtn: {
    textTransform: "none",
    fontSize: 16,
  },
  emsLogoIcon: {
    width: 30,
  },
  body2: {
    marginTop: 12,
    fontStyle: "italic",
  },
}));

export default function EmployeeStorePreCheckoutInfoDialog({
  handleCreatePayment,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { preCheckoutInfo, closePreCheckoutConfirmationDialog } = useInfo();

  const handleConfirm = () => {
    closePreCheckoutConfirmationDialog();
    if (!isNil(handleCreatePayment) && isFunction(handleCreatePayment)) {
      handleCreatePayment();
    }
  };

  const continueText = t("EMPLOYEE_STORE.DIALOG_EMS_CLOSED_ACTION");

  return (
    <Dialog
      open={preCheckoutInfo?.showPreCheckoutConfirmationDialog}
      maxWidth="lg"
    >
      <DialogContent>
        <DialogContentText variant="h4">
          {preCheckoutInfo?.preCheckoutConfirmationDialogTitle}
        </DialogContentText>
        {!isNil(preCheckoutInfo?.preCheckoutConfirmationDialogBodyItems) &&
          isArray(preCheckoutInfo?.preCheckoutConfirmationDialogBodyItems) &&
          !isEmpty(preCheckoutInfo?.preCheckoutConfirmationDialogBodyItems) && (
            <Box className={classes.bodySubtitle}>
              {preCheckoutInfo?.preCheckoutConfirmationDialogBodyItems.map(
                (item, index) => (
                  <Typography
                    key={index}
                    variant="h6"
                  >{`• ${item}`}</Typography>
                )
              )}
            </Box>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          color="primary"
          className={classes.actionBtn}
        >
          <Typography variant="h6">
            {`${continueText.substring(0, continueText.length - 2)}`}
          </Typography>
        </Button>
        <Button
          onClick={closePreCheckoutConfirmationDialog}
          className={classes.actionBtn}
        >
          <Typography variant="h6">{`${t("FORM_CANCEL")}`}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
