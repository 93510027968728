import { METHOD_GET } from "../../../util/Constants";
import { BASE_EMS_URL, handleApiResponse } from "./base";

const GET_POLICY_ENDPOINT = "/info/get-policy";
const GET_INITIAL_INFO_ENDPOINT = "/info/get-initial-user-info";
const GET_PRE_CHECKOUT_INFO_ENDPOINT = "/info/pre-checkout";

export const fetchPolicyApi = async (headers, logout) => {
  const response = await fetch(`${BASE_EMS_URL}${GET_POLICY_ENDPOINT}`, {
    method: METHOD_GET,
    headers: headers,
  });

  return handleApiResponse(response, logout);
};

export const fetchInitialInfoApi = async (headers, logout) => {
  const response = await fetch(`${BASE_EMS_URL}${GET_INITIAL_INFO_ENDPOINT}`, {
    method: METHOD_GET,
    headers: headers,
  });

  return handleApiResponse(response, logout);
};

export const fetchPreCheckoutInfoApi = async (headers, logout) => {
  const response = await fetch(
    `${BASE_EMS_URL}${GET_PRE_CHECKOUT_INFO_ENDPOINT}`,
    {
      method: METHOD_GET,
      headers: headers,
    }
  );

  return handleApiResponse(response, logout);
};
