import { isEmpty, isNil } from "lodash";
import { toast } from "react-toastify";

import { MOCKED_SIMULATION_DELAY } from "../../api/mocks";
import {
  getStatusSimulationRequestApi,
  startSimulationRequestApi,
} from "../../api/simulationApi";
import { commitTransactionApi } from "../../api/paymentsApi";
import { TOAST_CONTAINER_LAYOUT } from "../../../../util/Constants";
import {
  EMS_PAYMENT_STATUS_AUTHORIZED,
  EMS_PAYMENT_STATUS_FAILED,
  EMS_PAYMENT_STATUS_INITIALIZED,
  EMS_PAYMENT_STATUS_NULLIFIED,
  EMS_PAYMENT_STATUS_REVERSED,
} from "../../util/util-ems";

const EMS_SIMULATION_DELAY_IN_MS = 2 * 1000;
const EMS_MAX_ATTEMPTS = 30;

const createCheckoutSlice = (set, get) => ({
  checkoutStep: 0,
  resetCheckoutStep: () => set(() => ({ checkoutStep: 0 })),
  handleNextCheckoutStep: () =>
    set((state) => ({ checkoutStep: state.checkoutStep + 1 })),
  handleBackCheckoutStep: () => {
    set((state) => {
      if (state.checkoutStep === 1) {
        return {
          checkoutStep: state.checkoutStep - 1,
          responseSimulations: new Map(),
          simulationsIds: [],
          parentSimulationId: null,
        };
      } else {
        return { checkoutStep: state.checkoutStep - 1 };
      }
    });
  },
  isSimulating: false,
  parentSimulationId: null,
  simulationsIds: [],
  responseSimulations: new Map(),
  showSimulationDetails: false,
  showErrorDialogSimulating: false,
  openShowErrorDialogSimulation: () =>
    set(() => ({ showErrorDialogSimulating: true })),
  closeShowErrorDialogSimulation: () =>
    set(() => ({ showErrorDialogSimulating: false })),
  handleSimulation: async ({ headers, logout, t }) => {
    set({ isSimulating: true });
    const products = get().cartItems;

    const response = await startSimulationRequestApi({
      headers,
      body: { products },
      logout,
    });

    if (!isNil(response)) {
      const { data, error } = response;
      if (isNil(error) && !isNil(data)) {
        const { simulationsIds = [], parentSimulationId } = data;
        if (!isNil(simulationsIds) && !isEmpty(simulationsIds)) {
          set({ simulationsIds: simulationsIds || [], parentSimulationId });
        } else {
          toast.warn("", {
            containerId: TOAST_CONTAINER_LAYOUT,
          });
        }
      } else {
        toast.error(error || t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
          containerId: TOAST_CONTAINER_LAYOUT,
        });
      }
    } else {
      toast.error(t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
        containerId: TOAST_CONTAINER_LAYOUT,
      });
    }

    const simulationsIds = get().simulationsIds;
    const handleRequestSimulationStatus = get().handleRequestSimulationStatus;

    if (!isEmpty(simulationsIds)) {
      await handleRequestSimulationStatus({ headers, logout, t });
    } else {
      set((state) => {
        return {
          ...state,
          isSimulating: false,
        };
      });
    }
  },
  handleRequestSimulationStatus: async ({ headers, logout, t }) => {
    const simulationsIds = get().simulationsIds;
    const responseSimulations = get().responseSimulations;
    if (isNil(simulationsIds) || isEmpty(simulationsIds)) {
      toast.error(t("EMPLOYEE_STORE.SIMULATION_NO_IDS"), {
        containerId: TOAST_CONTAINER_LAYOUT,
      });
      return;
    }

    const checkSimulationStatus = async (sid) => {
      const response = await getStatusSimulationRequestApi({
        headers,
        simulationId: sid,
        logout,
      });

      if (!isNil(response)) {
        const { data, error } = response;
        if (isNil(error) && !isNil(data)) {
          if (data.simulationComplete) {
            set((state) => {
              const updatedResponseSimulations = new Map(
                state.responseSimulations
              );
              updatedResponseSimulations.set(data.simulationId, data.items);
              return { responseSimulations: updatedResponseSimulations };
            });
          }
          return data.simulationComplete;
        }
      }
      return false;
    };

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    let attempts = 0;
    let incompleteSimulations = simulationsIds.filter(
      (sid) => !responseSimulations.has(sid)
    );

    while (incompleteSimulations.length > 0 && attempts < EMS_MAX_ATTEMPTS) {
      const statuses = await Promise.all(
        incompleteSimulations.map(async (sid) => {
          return { sid, complete: await checkSimulationStatus(sid) };
        })
      );

      incompleteSimulations = statuses
        .filter((status) => !status.complete)
        .map((status) => status.sid);

      if (incompleteSimulations.length > 0) {
        attempts++;
        await delay(EMS_SIMULATION_DELAY_IN_MS);
      }
    }

    if (incompleteSimulations.length === 0) {
      toast.success(t("EMPLOYEE_STORE.SIMULATION_ALL_COMPLETE"), {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "bottom-right",
      });

      set((state) => ({
        isSimulating: false,
        checkoutStep: state.checkoutStep + 1,
      }));
    } else {
      set(() => ({
        isSimulating: false,
        checkoutStep: 0,
        responseSimulations: new Map(),
        showErrorDialogSimulating: true,
      }));
    }
  },
  toggleSimulationDetails: () =>
    set((state) => ({ showSimulationDetails: !state.showSimulationDetails })),
  handleCompleteCheckout: () => {
    set({ isSimulating: true });
    setTimeout(() => {
      set((state) => ({
        isSimulating: false,
        checkoutStep: state.checkoutStep + 1,
      }));
    }, MOCKED_SIMULATION_DELAY * 2);
  },
  validatingPayment: false,
  setValidatingPayment: (value) => set({ validatingPayment: value }),
  paymentStatus: EMS_PAYMENT_STATUS_INITIALIZED,
  paymentMessage: "",
  paymentSystemResponse: null,
  itemsDescription: null,
  //TODO: translate
  handleCommitPaymentTransaction: async ({
    headers,
    logout,
    body,
    paymentSystem,
  }) => {
    set({
      validatingPayment: true,
      paymentStatus: "processing",
      paymentMessage: "",
    });

    try {
      const response = await commitTransactionApi({
        headers,
        logout,
        body,
        paymentSystem,
      });

      const isOk = response?.ok === true;
      if (!isOk && !isNil(response?.error)) {
        toast.error(response?.error, {
          containerId: TOAST_CONTAINER_LAYOUT,
          toastId: "handleCommitPaymentTransaction",
          autoClose: false,
        });
      }

      if (response.data.status === EMS_PAYMENT_STATUS_AUTHORIZED) {
        set({
          paymentStatus: EMS_PAYMENT_STATUS_AUTHORIZED,
          paymentMessage: `Pago aprobado! Compra: #${response.data?.emsIdCompra}`,
          paymentSystemResponse: response?.data?.paymentSystemResponse,
          itemsDescription: response?.data?.itemsDescription,
        });
        return true;
      } else if (response.data.status === EMS_PAYMENT_STATUS_FAILED) {
        set({
          paymentStatus: EMS_PAYMENT_STATUS_FAILED,
          paymentMessage: `Pago rechazado. Código de respuesta: ${response.data?.paymentSystemResponse?.responseCode}`,
          paymentSystemResponse: response?.data?.paymentSystemResponse,
          itemsDescription: response?.data?.itemsDescription,
        });
      } else if (response.data.status === EMS_PAYMENT_STATUS_NULLIFIED) {
        set({
          paymentStatus: EMS_PAYMENT_STATUS_NULLIFIED,
          paymentMessage: `No se pudo procesar el pago. Por favor, intenta nuevamente.`,
          paymentSystemResponse: response?.data?.paymentSystemResponse,
          itemsDescription: response?.data?.itemsDescription,
        });
      } else if (response.data.status === EMS_PAYMENT_STATUS_REVERSED) {
        set({
          paymentStatus: EMS_PAYMENT_STATUS_REVERSED,
          paymentMessage: `Pago cancelado.`,
          paymentSystemResponse: response?.data?.paymentSystemResponse,
          itemsDescription: response?.data?.itemsDescription,
        });
      } else {
        set({
          paymentStatus: EMS_PAYMENT_STATUS_FAILED,
          paymentMessage:
            "No se pudo procesar el pago. Por favor, intenta nuevamente.",
          paymentSystemResponse: response?.data?.paymentSystemResponse,
          itemsDescription: response?.data?.itemsDescription,
        });
      }
    } catch (error) {
      set({
        paymentStatus: EMS_PAYMENT_STATUS_FAILED,
        paymentMessage:
          "Ocurrió un error al procesar tu pago. Por favor, intenta nuevamente.",
        paymentSystemResponse: null,
        itemsDescription: null,
      });
    } finally {
      set({ validatingPayment: false });
    }
  },
  resetCheckoutSlice: () => {
    set({
      checkoutStep: 0,
      isSimulating: false,
      parentSimulationId: null,
      simulationsIds: [],
      responseSimulations: new Map(),
      showSimulationDetails: false,
      showErrorDialogSimulating: false,
      validatingPayment: false,
      paymentStatus: EMS_PAYMENT_STATUS_INITIALIZED,
      paymentMessage: "",
      paymentSystemResponse: null,
      itemsDescription: null,
    });
  },
});

export default createCheckoutSlice;
