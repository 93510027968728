import { toast } from "react-toastify";
import {
  addCartProductApi,
  clearCartApi,
  getCartProductsApi,
  removeCartProductApi,
} from "../../api/cartApi";
import { isArray, isEmpty, isNil, isNumber } from "lodash";
import { TOAST_CONTAINER_LAYOUT } from "../../../../util/Constants";

const createBagSlice = (set, get) => ({
  openBag: false,
  toggleOpenBag: () => set((state) => ({ openBag: !state.openBag })),
  closeOpenBag: () => set(() => ({ openBag: false })),
  openBagDock: () => set(() => ({ openBag: true })),

  cartItems: [],
  emsCartId: null,

  loadingCart: false,

  fetchCart: async ({ t, headers, logout }) => {
    set({ loadingCart: true });
    try {
      const response = await getCartProductsApi({
        headers,
        logout,
      });

      if (response != null) {
        const { ok, data, error } = response;
        if (ok && !isNil(data)) {
          const { emsIdCompra, products } = data;
          set(() => ({
            cartItems: products,
            emsCartId: emsIdCompra,
          }));
        } else if (ok && isNil(data)) {
          // clear cart
          set(() => ({
            cartItems: [],
            emsCartId: null,
          }));
        } else {
          toast.error(error, {
            containerId: TOAST_CONTAINER_LAYOUT,
          });
        }
      } else {
        toast.error(t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
          containerId: TOAST_CONTAINER_LAYOUT,
        });
      }
    } catch (e) {
      toast.error(e);
    } finally {
      set({ loadingCart: false });
    }
  },

  addCartItem: async ({ t, headers, logout, product, quantity }) => {
    set({ loadingCart: true });
    try {
      const emsCartId = get().emsCartId;
      const { ean, catalogCode } = product;
      const emsIdCompra =
        !isNil(emsCartId) && isNumber(emsCartId) && emsCartId > 0
          ? emsCartId
          : null;
      const response = await addCartProductApi({
        headers,
        logout,
        params: { emsIdCompra, ean, catalogCode, quantity },
      });

      if (response != null) {
        const { ok, data, error } = response;
        if (ok && !isNil(data)) {
          const { emsIdCompra, products } = data;
          set(() => ({
            cartItems: products,
            emsCartId: emsIdCompra,
          }));
        } else {
          toast.error(error, {
            containerId: TOAST_CONTAINER_LAYOUT,
          });
        }
      } else {
        toast.error(t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
          containerId: TOAST_CONTAINER_LAYOUT,
        });
      }
    } catch (e) {
      toast.error(e);
    } finally {
      set({ loadingCart: false });
    }
  },
  removeCartItem: async ({ t, headers, logout, product }) => {
    set({ loadingCart: true });
    try {
      const emsCartId = get().emsCartId;
      const { ean } = product;
      const response = await removeCartProductApi({
        headers,
        logout,
        params: { emsIdCompra: emsCartId, ean },
      });

      if (response != null) {
        const { ok, data, error } = response;
        if (ok && !isNil(data)) {
          const { emsIdCompra, products } = data;
          set(() => ({
            cartItems:
              !isNil(products) && isArray(products) && !isEmpty(products)
                ? products
                : [],
            emsCartId: emsIdCompra,
          }));
        } else {
          toast.error(error, {
            containerId: TOAST_CONTAINER_LAYOUT,
          });
        }
      } else {
        toast.error(t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
          containerId: TOAST_CONTAINER_LAYOUT,
        });
      }
    } catch (e) {
      toast.error(e);
    } finally {
      set({ loadingCart: false });
    }
  },
  clearCartItems: async ({ t, headers, logout }) => {
    set({ loadingCart: true });
    try {
      const emsCartId = get().emsCartId;
      const response = await clearCartApi({
        headers,
        logout,
        params: { emsIdCompra: emsCartId },
      });

      if (response != null) {
        const { ok, error } = response;
        if (ok) {
          set(() => ({
            cartItems: [],
            emsCartId: null,
          }));
        } else {
          toast.error(error, {
            containerId: TOAST_CONTAINER_LAYOUT,
          });
        }
      } else {
        toast.error(t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
          containerId: TOAST_CONTAINER_LAYOUT,
        });
      }
    } catch (e) {
      toast.error(e);
    } finally {
      set({ loadingCart: false });
    }
  },
  clearCartStateOnlyFrontend: () => {
    set({
      cartItems: [],
      emsCartId: null,
      loadingCart: false,
    });
  },
  resetBagSlice: () => {
    set({
      openBag: false,
      cartItems: [],
      emsCartId: null,
      loadingCart: false,
    });
  },
});

export default createBagSlice;
