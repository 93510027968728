import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Icon,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import EmployeStoreBag from "./components/EmployeStoreBag";
import EmployeeStoreHistory from "./components/EmployeeStoreHistory";
import EmployeeStoreFavsButton from "./components/EmployeeStoreFavsButton";
import EmployeeStoreBack from "./components/EmployeeStoreBack";
import EmployeeStoreLogo from "./components/EmployeeStoreLogo";
import EmployeeStoreHomeButton from "./components/EmployeeStoreHomeButton";
import UsernameAndClock from "../../../../shared/layout/components/appBar/info/UsernameAndClock";
import HomeFilterBar from "../../components/HomeFilterBar";
import useFilters from "../../store/hooks/useFilters";
import EmployeeStoreProfileAvatar from "./components/EmployeeStoreProfileAvatar";
import EmployeeStoreNavigatorMobileMenu from "./components/EmployeeStoreNavigatorMobileMenu";
import { isNil } from "lodash";
import useProducts from "../../store/hooks/useProducts";
import EmployeeStoreClosedStoreBadge from "./components/EmployeeStoreClosedStoreBadge";

export const HEADER_HEIGHT = 68;
export const HEADER_HEIGHT_MOBILE_HOME = 130;

const useStyles = makeStyles((theme) => ({
  appBar: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "auto",
    minHeight: ({ isHomePage }) =>
      isHomePage && !theme.breakpoints.up("md")
        ? HEADER_HEIGHT_MOBILE_HOME
        : HEADER_HEIGHT,
    background: "rgb(255 255 255 / 75%)",
    boxShadow: "0 4px 30px rgb(0 0 0 / 18%)",
    backdropFilter: "blur(15px)",
    position: "fixed",
    top: 0,
    zIndex: 10,
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: theme.spacing(0, 2),
  },
  startContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    [theme.breakpoints.up("md")]: {
      flexWrap: "wrap",
    },
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
    [theme.breakpoints.up("md")]: {
      flexWrap: "wrap",
      gap: 10,
    },
  },
  searchInput: {
    width: "90%",
    margin: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      width: 300,
    },
  },
}));

const EmployeeStoreHeader = ({ isHomePage }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles({ isHomePage });
  const { t } = useTranslation();
  const [isFilterBarOpen, setIsFilterBarOpen] = useState(false);

  const { searchInput, handleSearchInputChange, handleApplyFilters } =
    useFilters();
  const { loadProducts } = useProducts();
  const [internalInput, setInternalInput] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchInputChange(internalInput);
      handleApplyFilters();
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setInternalInput(value);
  };

  const handleClearSearchInput = () => {
    setInternalInput("");
    handleSearchInputChange("");
    handleApplyFilters();
    loadProducts();
  };

  const handleBlur = () => {
    if (internalInput !== "" && internalInput !== searchInput) {
      handleSearchInputChange(internalInput);
      handleApplyFilters();
    }
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.startContainer}>
          <EmployeeStoreBack />
          <EmployeeStoreLogo />
          {isMdUp && <EmployeeStoreHomeButton />}
          {isMdUp && isHomePage && (
            <TextField
              size="small"
              id="outlined-start-adornment"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="inherit"
                      aria-label="search-button"
                      onClick={handleApplyFilters}
                    >
                      <SearchIcon />
                    </IconButton>
                    {(!isNil(searchInput) && searchInput !== "") ||
                    (!isNil(internalInput) && internalInput !== "") ? (
                      <IconButton
                        color="inherit"
                        aria-label="clear-search-button"
                        onClick={handleClearSearchInput}
                      >
                        <Icon className="fas fa-times" />
                      </IconButton>
                    ) : null}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className={classes.searchInput}
              label={t("EMPLOYEE_STORE.SEARCH_LABEL")}
              value={
                internalInput === "" && searchInput !== ""
                  ? searchInput
                  : internalInput
              }
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              onBlur={handleBlur}
            />
          )}
        </Box>

        <Box className={classes.buttonsContainer}>
          {isSmUp && <EmployeeStoreClosedStoreBadge />}
          <EmployeStoreBag />
          {isSmUp ? (
            <>
              <EmployeeStoreFavsButton />
              <EmployeeStoreHistory />
            </>
          ) : (
            <EmployeeStoreNavigatorMobileMenu />
          )}

          {isMdUp && <UsernameAndClock />}
          <EmployeeStoreProfileAvatar />
        </Box>
      </Toolbar>

      {!isMdUp && isHomePage && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={2}
            width="100%"
          >
            <IconButton
              aria-label="open filter"
              onClick={() => setIsFilterBarOpen(!isFilterBarOpen)}
            >
              <Icon
                className="fas fa-filter"
                style={{
                  color: isFilterBarOpen
                    ? theme.palette.content.mainColor
                    : "inherit",
                }}
              />
            </IconButton>
            <TextField
              size="small"
              id="outlined-start-adornment"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="inherit"
                      aria-label="search-button"
                      onClick={handleApplyFilters}
                    >
                      <SearchIcon />
                    </IconButton>
                    {(!isNil(searchInput) && searchInput !== "") ||
                    (!isNil(internalInput) && internalInput !== "") ? (
                      <IconButton
                        color="inherit"
                        aria-label="clear-search-button"
                        onClick={handleClearSearchInput}
                      >
                        <Icon className="fas fa-times" />
                      </IconButton>
                    ) : null}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className={classes.searchInput}
              label={t("EMPLOYEE_STORE.SEARCH_LABEL")}
              value={
                internalInput === "" && searchInput !== ""
                  ? searchInput
                  : internalInput
              }
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              onBlur={handleBlur}
            />
          </Box>
          {isFilterBarOpen && <HomeFilterBar />}
        </>
      )}
    </AppBar>
  );
};

export default EmployeeStoreHeader;
