import useEmployeeStoreState from "../EmployeeStoreState";

const useGlobalEms = () => {
  const {
    resetBagSlice,
    resetBannerSlice,
    resetCheckoutSlice,
    resetFavoriteSlice,
    resetFilterSlice,
    resetHistorySlice,
    resetInfoSlice,
    resetProductSlice,
    resetQuickViewSlice,
  } = useEmployeeStoreState((state) => ({
    resetBagSlice: state.resetBagSlice,
    resetBannerSlice: state.resetBannerSlice,
    resetCheckoutSlice: state.resetCheckoutSlice,
    resetFavoriteSlice: state.resetFavoriteSlice,
    resetFilterSlice: state.resetFilterSlice,
    resetHistorySlice: state.resetHistorySlice,
    resetInfoSlice: state.resetInfoSlice,
    resetProductSlice: state.resetProductSlice,
    resetQuickViewSlice: state.resetQuickViewSlice,
  }));

  const resetCompleteEmsStoreState = () => {
    // RESET ALL
    resetBagSlice();
    resetBannerSlice();
    resetCheckoutSlice();
    resetFavoriteSlice();
    resetFilterSlice();
    resetHistorySlice();
    resetInfoSlice();
    resetProductSlice();
    resetQuickViewSlice();
  };

  return { resetCompleteEmsStoreState };
};

export default useGlobalEms;
