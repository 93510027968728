import React, { useCallback, useState } from "react";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import useFilters from "../../store/hooks/useFilters";
import { isEmpty, isNil } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  category: {
    marginBottom: theme.spacing(2),
  },
  subcategory: {
    marginLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  expandIcon: {
    cursor: "pointer",
    verticalAlign: "middle",
  },
  radio: {
    padding: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  checkBox: {
    padding: 4,
  },
}));

const FilterCheckbox = ({ filter }) => {
  const classes = useStyles();
  const [expandedOptions, setExpandedOptions] = useState({});
  const { selectedFilters, handleCheckboxFilterChange, blockFilters } =
    useFilters();

  const toggleExpand = (optionId) => {
    setExpandedOptions((prev) => ({
      ...prev,
      [optionId]: !prev[optionId],
    }));
  };

  const handleCheckboxChange = useCallback(
    (optionId, isChecked, filterId) => {
      handleCheckboxFilterChange(filterId, optionId, isChecked);
    },
    [handleCheckboxFilterChange]
  );

  const needToShow = !isNil(filter.options) && !isEmpty(filter.options);

  if (needToShow === false) {
    return null;
  }

  const renderOptions = (options, filterId, level = 0) => {
    return options.map((option) => {
      const isSelected =
        selectedFilters.checkbox?.[filterId]?.includes(option.id) || false;
      const hasSubOptions = option.subOptions && option.subOptions.length > 0;
      const isExpanded = expandedOptions[option.id] || false;

      // Determinar el estado indeterminado
      const subOptionIds = option.subOptions.map((sub) => sub.id);
      const selectedSubOptions = subOptionIds.filter((id) =>
        selectedFilters.checkbox?.[filterId]?.includes(id)
      );
      const isIndeterminate =
        selectedSubOptions.length > 0 &&
        selectedSubOptions.length < subOptionIds.length;

      return (
        <React.Fragment key={option.id}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  className={classes.checkBox}
                  style={{ marginLeft: `${level * 16}px` }}
                  checked={isSelected}
                  onChange={(e) =>
                    handleCheckboxChange(option.id, e.target.checked, filterId)
                  }
                  indeterminate={isIndeterminate}
                  disabled={blockFilters}
                />
              }
              label={option.name}
            />
            {hasSubOptions && (
              <IconButton size="small" onClick={() => toggleExpand(option.id)}>
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </Box>
          {hasSubOptions && (
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Box className={classes.subcategory}>
                {renderOptions(option.subOptions, filterId, level + 1)}
              </Box>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  const isFillterApplied =
    !isNil(selectedFilters?.checkbox?.[filter.id]) &&
    !isEmpty(selectedFilters?.checkbox?.[filter.id]);

  return (
    <Box className={classes.root}>
      <Box key={filter.id} className={classes.category}>
        <Typography variant="h6" className={classes.title}>
          {filter.name} {isFillterApplied ? "" : " (all)"}
        </Typography>
        <Box className={classes.subcategory}>
          {renderOptions(filter.options, filter.id)}
        </Box>
      </Box>
    </Box>
  );
};

export default FilterCheckbox;
