import { fetchBannersApi } from "../../api/bannersApi";

const createBannerSlice = (set) => ({
  bannerVisible: true,
  banner: null,
  loadingBanner: false,
  errorBanner: null,
  setBannerVisible: (visible) => set({ bannerVisible: visible }),
  fetchBanner: async (headers, logout) => {
    set({ loadingBanner: true, errorBanner: null });
    try {
      const { data, error } = await fetchBannersApi(headers, logout);
      if (data) {
        set({ banner: data?.banners, loadingBanner: false });
      } else {
        set({ errorBanner: error, loadingBanner: false });
      }
    } catch (error) {
      set({ errorBanner: error.message, loadingBanner: false });
    }
  },
  resetBannerSlice: () => {
    set({
      bannerVisible: true,
      banner: null,
      loadingBanner: false,
      errorBanner: null,
    });
  },
});

export default createBannerSlice;
