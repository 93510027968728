import {
  addFavoriteRequestApi,
  getFavoritesRequestApi,
  removeFavoriteRequestApi,
} from "../../api/favoriteApi";

const createFavoriteSlice = (set) => ({
  favorites: [],
  loadingFavorites: true,
  loadedFavoritedItems: false,

  getFavorites: async ({ headers, logout }) => {
    set({ loadingFavorites: true });
    try {
      const response = await getFavoritesRequestApi({ headers, logout });
      if (!response.error) {
        set({
          favorites: response.data.favorites,
          loadedFavoritedItems: true,
        });
      }
    } catch (_) {
    } finally {
      set({ loadingFavorites: false });
    }
  },
  addFavorite: async ({ headers, logout, product }) => {
    set({ loadingFavorites: true });
    try {
      const body = {
        ean: product.ean,
        codMaterial: product.sku,
      };

      const response = await addFavoriteRequestApi({ headers, logout, body });
      if (!response.error) {
        set({ favorites: response.data.favorites });
      }
    } catch (_) {
    } finally {
      set({ loadingFavorites: false });
    }
  },
  removeFavorite: async ({ headers, logout, product }) => {
    set({ loadingFavorites: true });
    try {
      const body = {
        ean: product.ean,
        codMaterial: product.sku,
      };

      const response = await removeFavoriteRequestApi({
        headers,
        logout,
        body,
      });
      if (!response.error) {
        set({ favorites: response.data.favorites });
      }
    } catch (_) {
    } finally {
      set({ loadingFavorites: false });
    }
  },
  resetFavoriteSlice: () => {
    set({
      favorites: [],
      loadingFavorites: true,
      loadedFavoritedItems: false,
    });
  },
});

export default createFavoriteSlice;
