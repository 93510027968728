import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useInfo from "../../../store/hooks/useInfo";
import { isNil } from "lodash";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    backgroundColor: "#7d3c98",
    padding: "6px",
    borderRadius: 8,
    gap: 6,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 12,
    },
  },
  closedImg: {
    width: 22,
  },
}));

export default function EmployeeStoreClosedStoreBadge() {
  const { t } = useTranslation();
  const classes = useStyles();

  const { initialInfo, firstInitialInfoCompleted, openClosedEmsDialog } =
    useInfo();

  const showBadge = useMemo(() => {
    if (!isNil(initialInfo) && firstInitialInfoCompleted === true) {
      const { isEmsClosed } = initialInfo;
      return isEmsClosed === true;
    }
  }, [firstInitialInfoCompleted, initialInfo]);

  if (!showBadge) {
    return null;
  }

  return (
    <Box className={classes.box} onClick={openClosedEmsDialog}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/img/employee-store/employee-strore-closed-icon.png`}
        alt="store-closed-badge"
        className={classes.closedImg}
      />
      <Typography variant="h6">
        {t("EMPLOYEE_STORE.DIALOG_EMS_CLOSED_TITLE")}
      </Typography>
    </Box>
  );
}
