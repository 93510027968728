import React, { useContext, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Icon,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import useFilters from "../store/hooks/useFilters";
import FilterCheckbox from "./filters/FilterCheckbox";
import FilterRange from "./filters/FilterRange";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../core/providers/AuthContext";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import { isEmpty, isNil } from "lodash";
import FilterSwitch from "./filters/FilterSwitch";
import {
  EMS_FILTER_CHECKBOX,
  EMS_FILTER_RANGE,
  EMS_FILTER_SWITCH,
} from "../util/util-ems";
import useProducts from "../store/hooks/useProducts";
import { HEADER_HEIGHT_MOBILE_HOME } from "../layout/header/EmployeeStoreHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
    color: theme.palette.text.primary,
    width: 275,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: `calc(100vh - ${HEADER_HEIGHT_MOBILE_HOME}px)`, 
      overflowY: "auto"
    },
  },
}));

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 2,
};

const FilterBar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const {
    availableFilters,
    selectedFilters,
    fetchFilters,
    handleRangeFilterChange,
    loadingFilters,
    handleResetFilters,
    blockFilters,
  } = useFilters();
  const { loadProducts } = useProducts();

  useEffect(() => {
    if (!loadingFilters && isEmpty(availableFilters)) {
      fetchFilters(REQUEST_HEADERS, logout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFilters]);

  async function handleRefreshFiltersMock() {
    await fetchFilters(REQUEST_HEADERS, logout);
    await loadProducts();
  }

  const renderFilter = (filter) => {
    switch (filter.type) {
      case EMS_FILTER_CHECKBOX:
        return (
          <FilterCheckbox
            key={filter.id}
            filter={filter}
            selectedValues={selectedFilters.checkbox?.[filter.id] || []}
          />
        );
      case EMS_FILTER_RANGE:
        return (
          <FilterRange
            key={filter.id}
            filter={filter}
            selectedRange={{
              min: selectedFilters.range?.[`${filter.id}From`] || "",
              max: selectedFilters.range?.[`${filter.id}To`] || "",
            }}
            onChange={(range) => handleRangeFilterChange(filter.id, range)}
          />
        );
      case EMS_FILTER_SWITCH:
        return <FilterSwitch key={filter.id} filter={filter} />;
      default:
        return null;
    }
  };
  const areFilterPresent = useMemo(() => {
    if (isNil(selectedFilters) || isEmpty(selectedFilters)) {
      return false;
    }

    // Check if any checkbox filter is not empty
    const hasCheckboxFilter = Object.values(
      selectedFilters.checkbox || {}
    ).some((filterValues) => !isEmpty(filterValues));

    // Check if any range filter is not empty
    const hasRangeFilter = Object.values(selectedFilters.range || {}).some(
      (value) => value !== "" && !isNil(value)
    );

    return hasCheckboxFilter || hasRangeFilter;
  }, [selectedFilters]);

  return (
    <Box className={classes.root}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5">
          {t("EMPLOYEE_STORE.FILTERS_TITLE")}
        </Typography>
        <IconButton
          onClick={() => handleRefreshFiltersMock()}
          disabled={blockFilters}
        >
          <Icon
            className="fas fa-sync"
            style={commonIconStyles}
            fontSize="small"
          />
        </IconButton>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        mb={1}
      >
        <Button
          variant="text"
          size="small"
          onClick={handleResetFilters}
          startIcon={
            <Icon
              className="fas fa-eraser"
              style={commonIconStyles}
              fontSize="small"
            />
          }
          disabled={!areFilterPresent || blockFilters}
        >
          {t("EMPLOYEE_STORE.FILTERS_CLEAN_ALL")}
        </Button>
      </Box>
      {loadingFilters ? (
        <div>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton width={250} height={150} />
          <Skeleton variant="text" />
          <Skeleton width={250} height={150} />
        </div>
      ) : (
        availableFilters.map((filter) => renderFilter(filter))
      )}
    </Box>
  );
};

export default FilterBar;
